<template>
  <div>
    <v-tabs v-model="tabs">
      <v-tab>{{$t('declarations')}}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <Declaration/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  import Declaration from './Declarations/Declaration.vue'
  export default {
    name: 'History',
    components: {
      Declaration
    },
    data () {
      return {
        tabs: null
      }
    },
    mounted() {
      this.$store.commit('setDataTitle', this.$i18n.t('measurementHistory'))
    }
  }
</script>

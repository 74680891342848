<template>
  <v-card flat>
    <DeclarationFilter
      :setFilter="setDeclarationRequestData"
      :clearFilter="clearFilter"
      ref="filter"
    />
    <Table
      :headers="headers"
      :items="declarations"
      :links="links"
      :load="tableLoader"
      :getDocument="setDeclarationRequestData"
      type="pagination"
    />
  </v-card>
</template>

<script>
import { getDate } from '@/mixins/main'
import { mapActions, mapState } from 'vuex'

import DeclarationFilter from './DeclarationFilter'

export default {
  name: 'declarations',
  components: {
    DeclarationFilter
  },
  data () {
    return {
      links: [
        {
          name: 'declarationDocument',
          icon: 'mdi-information-outline'
        },
      ],
      headers: [
        { text: this.$i18n.t('dateCreating'), value: 'created_at'},
        { text: 'VIN', value: 'vehicle.car_license_plate' },
        { text: this.$i18n.t('numberDocument'), value: 'number'},
        { text: this.$i18n.t('carriedMeasurments'), value: 'user'},
        { text: this.$i18n.t('measurementAddress'), value: 'address'},
        { text: this.$i18n.t('action'), value: 'actions' }
      ],
      tableLoader: false,
      getDate,

    }
  },
  computed: {
    ...mapState({
      declarations: state => state.history.declarations
    })
  },
  methods: {
    ...mapActions(['getDeclarations']),
    setDeclarationRequestData (link = null) {
      this.tableLoader = true
      let params = this.$refs.filter.filter
      params.link = link
      this.getDeclarations(params)
        .finally(() => { this.tableLoader = false })
    },
    clearFilter () {
      let params = this.$refs.filter.filter
      Object.entries(params).forEach(e => params[e[0]] = null)
    },
  },
  mounted () {
    this.setDeclarationRequestData()
  }
}
</script>

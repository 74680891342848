<template>
  <div class="finder search">
    <div class="d-flex w-100 justify-end mb-6" v-if="!isFilterPinned">
      <v-btn
        @click="isShow = !isShow"
        class="btn-primary">
        <v-icon>{{ isShow ? 'mdi-close' : 'mdi-plus' }}</v-icon> <b> {{ $t('filter') }}</b>
      </v-btn>
    </div>
    <v-card
      v-if="isShow || isFilterPinned"
      :class="['finder-list p-6 mb-6', { 'pinned': isFilterPinned }]"
      >
      <div>
        <div class="d-flex filterItems">
            <v-autocomplete
              v-model="filter.stand"
              :label="$t('choiceStand')"
              :items="stands"
              :no-data-text="$t('notify.noData')"
              item-text="name"
              item-value="id"
              class="w-100 mr-6"
            />
          <v-menu
            v-model="dateFromMenu"
            :close-on-content-click="false"
            max-width="290">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filter.date_from"
                :label="$t('dateFrom')"
                clearable
                v-bind="attrs"
                v-on="on"
                class="w-100"
                autocomplete="off"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filter.date_from"
              locale="uk"
              :max="new Date().toISOString().substr(0, 10)"
              @change="dateFromMenu = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            v-model="dateToMenu"
            :close-on-content-click="false"
            max-width="290">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filter.date_to"
                :label="$t('dateTo')"
                clearable
                v-bind="attrs"
                v-on="on"
                :class="['w-100',{ 'ml-6': isFilterPinned }]"
                autocomplete="off"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filter.date_to"
              locale="uk"
              :max="new Date().toISOString().substr(0, 10)"
              @change="dateToMenu = false"
            ></v-date-picker>
          </v-menu>
        </div>

        <div class="d-flex filterItems">
          <v-text-field
            v-model="filter.vin"
            label="VIN"
            :class="{ 'mr-6': isFilterPinned }"
            />
          <v-text-field
            v-model="filter.declaration_id"
            :label="$t('numberDocument')"
            :class="{ 'ml-6': isFilterPinned }"
            />
        </div>
      </div>
      <v-checkbox
      v-model="isFilterPinned"
      :label="$t('filterPin')"
      ></v-checkbox>
      <div class="d-flex justify-end align-center">
        <v-btn
          @click="setFilter()"
          color="green"
          dark
        >{{ $t('filter') }}</v-btn>
        <v-btn
          text
          @click="clearFilter()"
          color="primary"
          dark
        >{{ $t('filterClear') }}</v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'declararionFilter',
  props: {
    setFilter: Function,
    clearFilter: Function
  },
  data () {
    return {
      isShow: false,
      isLoad: false,
      isFilterPinned: false,
      dateFromMenu: false,
      dateToMenu: false,
      filter: {
        stand: '',
        date_from: '',
        date_to: '',
        vin: '',
        declaration_id: ''
      }
    }
  },
  computed: {
    ...mapState({
      stands: state => state.stand.standData.stands
    })
  }
}

</script>